<template>
    <div class="home">
      <el-menu :default-active="formInline.status+''" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="0">全部</el-menu-item>
        <el-menu-item index="1">启用</el-menu-item>
        <el-menu-item index="2">禁用</el-menu-item>
      </el-menu>
      <div class="pt20"></div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="认证时间">
                <el-date-picker
                    v-model="timevalue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="有效期">
              <el-select v-model="formInline.is_effect" placeholder="请选择">
                  <el-option label="请选择" :value="0"></el-option>
                  <el-option label="是" :value="1"></el-option>
                  <el-option label="否" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="认证分类">
              <el-select v-model="formInline.category" placeholder="状态">
                  <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="font-weight: bold;" label="关键字">
              <el-input style="width:300px" v-model="formInline.query" placeholder="操作人、认证编号、用户账号、用户编号"></el-input>
            </el-form-item>
            <el-form-item>
            <el-button type="primary" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
      </el-form> 
      <div class="pt20"></div>
      <div class="left">
        <el-button v-if="add == 1" @click="goNav('/usermanagement/authenticateduseradd')" type="primary" style="font-size: 16px;font-weight: bold;" plain>添加</el-button>
        <el-button v-if="dele == 1" @click="menuChangeStatus(3)" type="danger" style="font-size: 16px;font-weight: bold;" plain>删除</el-button>
        <el-button v-if="dele == 1" @click="menuChangeStatus(1)" type="success" style="font-size: 16px;font-weight: bold;" plain>启用</el-button>
        <el-button v-if="dele == 1" @click="menuChangeStatus(2)" type="warning" style="font-size: 16px;font-weight: bold;" plain>禁用</el-button>
      </div>

      <el-table
      v-loading="loading"
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange" >
       <el-table-column type="selection" width="55" align="center"/>
        <el-table-column label="序号" :index="typeIndex" type="index" show-overflow-tooltip width="50">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="用户账号"
          width="180">
        </el-table-column>
        <el-table-column
          prop="user.uid"
          label="用户编号">
        </el-table-column>
        <el-table-column
          prop="id" 
          label="认证编号">
        </el-table-column>
        <el-table-column
          prop="category"
          label="认证类型">
          <template slot-scope="scope">
            {{scope.row.category | authentication}}
          </template>
        </el-table-column>
        <el-table-column
          prop="sdate"
          width="300"
          label="有效时间">
           <template slot-scope="scope">
            {{scope.row.sdate}}一{{scope.row.edate}}
          </template>
        </el-table-column>
        <el-table-column
          prop="effect_status"
          label="生效">
        </el-table-column>
        <el-table-column
          prop="operator_name"
          label="操作人">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态">
          <template slot-scope="scope">
            {{scope.row.status == 1?'启用':''}}{{scope.row.status == 2?'禁用':''}}{{scope.row.status == 3?'删除':''}}
          </template>
        </el-table-column>
        <el-table-column
        width="180"
          label="操作">
          <template slot-scope="scope">
        <el-button v-if="edit == 1" @click="goedit(scope.row)" size="mini" type="info" plain>编辑</el-button>
        <el-button v-if="edit == 1" @click="goedit2(scope.row)" size="mini" type="info" plain>查看</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        
<el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="current_page"
      :page-size="per_page"
      layout="total,prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat'

let vm = {};
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      loading:true,
      add:location.add,
      dele:location.delete,
      edit:location.edit,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      formInline: {
        sdate:'',
        edate:'',
        query:'',
        category:'',
        page:1,
        status:0,
        is_effect:0,
      },
      initdata:{
        sdate:'',
        edate:'',
        query:'',
        category:'',
        page:1,
        status:0,
        is_effect:0,
      },
      timevalue:'',
      // 选中数组
      ghs: [],
      options:[],   
     //选中的记录数量
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  filters:{
      authentication:function(value){
          let arr = vm.options;
          for(let i in arr){
              if(arr[i].id == value){
                  return arr[i].name;
              }
          }
          return value;
      }
  },
        beforeCreate: function () {
            vm = this;
        },
  methods: {
      handleSelect(key) {
        this.formInline.status = key;
        this.onSubmit('CHA');
      },
      onSubmit(CHA){
        let _this = this;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
          _this.formInline.sdate = '';
          _this.formInline.edate = '';
          if(this.timevalue){
            this.formInline.sdate = formatDate(this.timevalue[0]);
            this.formInline.edate = formatDate(this.timevalue[1]);
          }
        _this.loading = true
        axios.get(config.authentication,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
    setpage(page){
      this.total = page.total;
      this.per_page = page.per_page;
      this.current_page = page.current_page;
    },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
      goNav(url){
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
        this.$router.push(url);
      },typeIndex(index) {
      let _this = this; //处理分页数据的 index
      return (_this.formInline.page - 1) * 20 + index + 1;
    },
      goedit(obj){
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
      //  this.$router.push('/usermanagement/authenticateduseredit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/usermanagement/authenticateduseredit');
      },
      goedit2(obj){
        obj.look = true;
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
       // this.$router.push('/usermanagement/authenticateduseredit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/usermanagement/authenticateduseredit');
      },
      menuChangeStatus(status){
          let _this = this;
        if(this.selectedNum == 0){
          this.$message.error('请先勾选');
          return;
        }
        this.$confirm('确认执行操作？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post(config.authenticationChangeStatus, {id:this.ghs,status:status})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.onSubmit();
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });

      },// 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.id)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
        console.log(this.ghs);
        console.log(this.selectedNum);
      },
    init(){
        let _this = this;
        let a = sessionStorage.getItem('formInline');
        this.formInline = a?JSON.parse(a):this.initdata;
        this.formInline.category = this.formInline.category?this.formInline.category:-1;
        axios.get(config.authentication,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
    ininoptions(){
        let _this = this;
        axios.get(config.selectauthentication,{})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options = response.data.data
              _this.init();
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.ininoptions();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
    text-align: left;
}
</style>